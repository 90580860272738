
    <template>
      <section class="content element-doc">
        <h2>Form 表单</h2>
<p>由输入框、选择器、单选框、多选框等控件组成，用以收集、校验、提交数据</p>
<h3>典型表单</h3>
<p>包括各种表单项，比如输入框、选择器、开关、单选框、多选框等。</p>
<demo-block>
        <div><p>在 Form 组件中，每一个表单域由一个 Form-Item 组件构成，表单域中可以放置各种类型的表单控件，包括 Input、Select、Checkbox、Radio、Switch、DatePicker、TimePicker</p>
</div>
        <template #source><element-demo0 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-form ref=&quot;formRef&quot; :model=&quot;form&quot; label-width=&quot;auto&quot;&gt;
  &lt;el-form-item label=&quot;活动名称&quot;&gt;
    &lt;el-input v-model=&quot;form.name&quot;&gt;&lt;/el-input&gt;
  &lt;/el-form-item&gt;
  &lt;el-form-item label=&quot;即时配送&quot;&gt;
    &lt;el-switch v-model=&quot;form.delivery&quot;&gt;&lt;/el-switch&gt;
  &lt;/el-form-item&gt;
  &lt;el-form-item label=&quot;活动性质&quot;&gt;
    &lt;el-checkbox-group v-model=&quot;form.type&quot;&gt;
      &lt;el-checkbox label=&quot;美食/餐厅线上活动&quot; name=&quot;type&quot;&gt;&lt;/el-checkbox&gt;
      &lt;el-checkbox label=&quot;地推活动&quot; name=&quot;type&quot;&gt;&lt;/el-checkbox&gt;
      &lt;el-checkbox label=&quot;线下主题活动&quot; name=&quot;type&quot;&gt;&lt;/el-checkbox&gt;
      &lt;el-checkbox label=&quot;单纯品牌曝光&quot; name=&quot;type&quot;&gt;&lt;/el-checkbox&gt;
    &lt;/el-checkbox-group&gt;
  &lt;/el-form-item&gt;
  &lt;el-form-item label=&quot;特殊资源&quot;&gt;
    &lt;el-radio-group v-model=&quot;form.resource&quot;&gt;
      &lt;el-radio label=&quot;线上品牌商赞助&quot;&gt;&lt;/el-radio&gt;
      &lt;el-radio label=&quot;线下场地免费&quot;&gt;&lt;/el-radio&gt;
    &lt;/el-radio-group&gt;
  &lt;/el-form-item&gt;
  &lt;el-form-item label=&quot;活动形式&quot;&gt;
    &lt;el-input type=&quot;textarea&quot; v-model=&quot;form.desc&quot;&gt;&lt;/el-input&gt;
  &lt;/el-form-item&gt;
  &lt;el-form-item&gt;
    &lt;el-button type=&quot;primary&quot; @click=&quot;onSubmit&quot;&gt;立即创建&lt;/el-button&gt;
    &lt;el-button&gt;取消&lt;/el-button&gt;
  &lt;/el-form-item&gt;
&lt;/el-form&gt;

&lt;script&gt;
  import { ref, reactive, toRefs } from 'vue'
  export default {
    setup() {
      const formRef = ref(null)
      const data = reactive({
        form: {
          name: '',
          region: '',
          date1: '',
          date2: '',
          delivery: false,
          type: [],
          resource: '',
          desc: ''
        }
      })
      const onSubmit = () =&gt; {
        console.log('submit!')
      }

      return {
        ...toRefs(data),
        formRef,
        onSubmit
      }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>行内表单</h3>
<p>当垂直方向空间受限且表单较简单时，可以在一行内放置表单。</p>
<demo-block>
        <div><p>设置 <code>inline</code> 属性可以让表单域变为行内的表单域</p>
</div>
        <template #source><element-demo1 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-form :inline=&quot;true&quot; :model=&quot;formInline&quot; class=&quot;demo-form-inline&quot;&gt;
  &lt;el-form-item label=&quot;审批人&quot;&gt;
    &lt;el-input v-model=&quot;formInline.user&quot; placeholder=&quot;审批人&quot;&gt;&lt;/el-input&gt;
  &lt;/el-form-item&gt;
  &lt;el-form-item label=&quot;发送通知&quot;&gt;
    &lt;el-switch v-model=&quot;formInline.delivery&quot;&gt;&lt;/el-switch&gt;
  &lt;/el-form-item&gt;
  &lt;el-form-item&gt;
    &lt;el-button type=&quot;primary&quot; @click=&quot;onSubmit&quot;&gt;查询&lt;/el-button&gt;
  &lt;/el-form-item&gt;
&lt;/el-form&gt;
&lt;script&gt;
  import { reactive, toRefs } from 'vue'
  export default {
    setup() {
      const data = reactive({
        formInline: {
          user: '',
          delivery: false
        }
      })
      const onSubmit = () =&gt; {
        console.log('submit!')
      }

      return {
        ...toRefs(data),
        onSubmit
      }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>对齐方式</h3>
<p>根据具体目标和制约因素，选择最佳的标签对齐方式。</p>
<demo-block>
        <div><p>通过设置 <code>label-position</code> 属性可以改变表单域标签的位置，可选值为 <code>top</code>、<code>left</code>，当设为 <code>top</code> 时标签会置于表单域的顶部</p>
</div>
        <template #source><element-demo2 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-radio-group v-model=&quot;labelPosition&quot; size=&quot;small&quot;&gt;
  &lt;el-radio-button label=&quot;left&quot;&gt;左对齐&lt;/el-radio-button&gt;
  &lt;el-radio-button label=&quot;right&quot;&gt;右对齐&lt;/el-radio-button&gt;
  &lt;el-radio-button label=&quot;top&quot;&gt;顶部对齐&lt;/el-radio-button&gt;
&lt;/el-radio-group&gt;
&lt;div style=&quot;margin: 20px;&quot;&gt;&lt;/div&gt;
&lt;el-form
  :label-position=&quot;labelPosition&quot;
  label-width=&quot;80px&quot;
  :model=&quot;formLabelAlign&quot;
&gt;
  &lt;el-form-item label=&quot;名称&quot;&gt;
    &lt;el-input v-model=&quot;formLabelAlign.name&quot;&gt;&lt;/el-input&gt;
  &lt;/el-form-item&gt;
  &lt;el-form-item label=&quot;活动区域&quot;&gt;
    &lt;el-input v-model=&quot;formLabelAlign.region&quot;&gt;&lt;/el-input&gt;
  &lt;/el-form-item&gt;
  &lt;el-form-item label=&quot;活动形式&quot;&gt;
    &lt;el-input v-model=&quot;formLabelAlign.type&quot;&gt;&lt;/el-input&gt;
  &lt;/el-form-item&gt;
&lt;/el-form&gt;
&lt;script&gt;
  import { reactive, toRefs, ref } from 'vue'
  export default {
    setup() {
      const labelPosition = ref('right')
      const data = reactive({
        formLabelAlign: {
          name: '',
          region: '',
          type: ''
        }
      })
      return {
        labelPosition,
        ...toRefs(data)
      }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>表单验证</h3>
<p>在防止用户犯错的前提下，尽可能让用户更早地发现并纠正错误。</p>
<demo-block>
        <div><p>Form 组件提供了表单验证的功能，只需要通过 <code>rules</code> 属性传入约定的验证规则，并将 Form-Item 的 <code>prop</code> 属性设置为需校验的字段名即可。校验规则参见 <a href="https://github.com/yiminghe/async-validator">async-validator</a></p>
</div>
        <template #source><element-demo3 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-form
  :model=&quot;ruleForm&quot;
  :rules=&quot;rules&quot;
  ref=&quot;form&quot;
  label-width=&quot;100px&quot;
  class=&quot;demo-ruleForm&quot;
&gt;
  &lt;el-form-item label=&quot;活动名称&quot; prop=&quot;name&quot;&gt;
    &lt;el-input v-model=&quot;ruleForm.name&quot;&gt;&lt;/el-input&gt;
  &lt;/el-form-item&gt;
  &lt;el-form-item label=&quot;活动区域&quot; prop=&quot;region&quot;&gt;
    &lt;el-select v-model=&quot;ruleForm.region&quot; placeholder=&quot;请选择活动区域&quot;&gt;
      &lt;el-option label=&quot;区域一&quot; value=&quot;shanghai&quot;&gt;&lt;/el-option&gt;
      &lt;el-option label=&quot;区域二&quot; value=&quot;beijing&quot;&gt;&lt;/el-option&gt;
    &lt;/el-select&gt;
  &lt;/el-form-item&gt;
  &lt;el-form-item label=&quot;即时配送&quot; prop=&quot;delivery&quot;&gt;
    &lt;el-switch v-model=&quot;ruleForm.delivery&quot;&gt;&lt;/el-switch&gt;
  &lt;/el-form-item&gt;
  &lt;el-form-item label=&quot;活动性质&quot; prop=&quot;type&quot;&gt;
    &lt;el-checkbox-group v-model=&quot;ruleForm.type&quot;&gt;
      &lt;el-checkbox label=&quot;美食/餐厅线上活动&quot; name=&quot;type&quot;&gt;&lt;/el-checkbox&gt;
      &lt;el-checkbox label=&quot;地推活动&quot; name=&quot;type&quot;&gt;&lt;/el-checkbox&gt;
      &lt;el-checkbox label=&quot;线下主题活动&quot; name=&quot;type&quot;&gt;&lt;/el-checkbox&gt;
      &lt;el-checkbox label=&quot;单纯品牌曝光&quot; name=&quot;type&quot;&gt;&lt;/el-checkbox&gt;
    &lt;/el-checkbox-group&gt;
  &lt;/el-form-item&gt;
  &lt;el-form-item label=&quot;特殊资源&quot; prop=&quot;resource&quot;&gt;
    &lt;el-radio-group v-model=&quot;ruleForm.resource&quot;&gt;
      &lt;el-radio label=&quot;线上品牌商赞助&quot;&gt;&lt;/el-radio&gt;
      &lt;el-radio label=&quot;线下场地免费&quot;&gt;&lt;/el-radio&gt;
    &lt;/el-radio-group&gt;
  &lt;/el-form-item&gt;
  &lt;el-form-item label=&quot;活动形式&quot; prop=&quot;desc&quot;&gt;
    &lt;el-input type=&quot;textarea&quot; v-model=&quot;ruleForm.desc&quot;&gt;&lt;/el-input&gt;
  &lt;/el-form-item&gt;
  &lt;el-form-item&gt;
    &lt;el-button type=&quot;primary&quot; @click=&quot;submitForm('ruleForm')&quot;
      &gt;立即创建&lt;/el-button
    &gt;
    &lt;el-button @click=&quot;resetForm('ruleForm')&quot;&gt;重置&lt;/el-button&gt;
  &lt;/el-form-item&gt;
&lt;/el-form&gt;
&lt;script&gt;
  import { ref, reactive, toRefs, getCurrentInstance } from 'vue'
  export default {
    setup() {
      const form = ref(null)
      const ruleForm = reactive({
        ruleForm: {
          name: '',
          region: '',
          delivery: false,
          type: [],
          resource: '',
          desc: ''
        }
      })
      const rules = reactive({
        rules: {
          name: [
            { required: true, message: '请输入活动名称', trigger: 'blur' },
            { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
          ],
          region: [
            { required: true, message: '请选择活动区域', trigger: 'change' }
          ],
          type: [
            {
              type: 'array',
              required: true,
              message: '请至少选择一个活动性质',
              trigger: 'change'
            }
          ],
          resource: [
            { required: true, message: '请选择活动资源', trigger: 'change' }
          ],
          desc: [{ required: true, message: '请填写活动形式', trigger: 'blur' }]
        }
      })
      function submitForm() {
        form.value.validate((valid) =&gt; {
          if (valid) {
            alert('submit!')
          } else {
            console.log('error submit!!')
            return false
          }
        })
      }
      function resetForm() {
        form.value.resetFields()
      }

      return {
        form,
        ...toRefs(ruleForm),
        ...toRefs(rules),
        submitForm,
        resetForm
      }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>自定义校验规则</h3>
<p>这个例子中展示了如何使用自定义验证规则来完成密码的二次验证。</p>
<demo-block>
        <div><p>本例还使用<code>status-icon</code>属性为输入框添加了表示校验结果的反馈图标。</p>
</div>
        <template #source><element-demo4 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-form
  :model=&quot;ruleForm&quot;
  status-icon
  :rules=&quot;rules&quot;
  ref=&quot;ruleForm&quot;
  label-width=&quot;100px&quot;
  class=&quot;demo-ruleForm&quot;
&gt;
  &lt;el-form-item label=&quot;密码&quot; prop=&quot;pass&quot;&gt;
    &lt;el-input
      type=&quot;password&quot;
      v-model=&quot;ruleForm.pass&quot;
      autocomplete=&quot;off&quot;
    &gt;&lt;/el-input&gt;
  &lt;/el-form-item&gt;
  &lt;el-form-item label=&quot;确认密码&quot; prop=&quot;checkPass&quot;&gt;
    &lt;el-input
      type=&quot;password&quot;
      v-model=&quot;ruleForm.checkPass&quot;
      autocomplete=&quot;off&quot;
    &gt;&lt;/el-input&gt;
  &lt;/el-form-item&gt;
  &lt;el-form-item label=&quot;年龄&quot; prop=&quot;age&quot;&gt;
    &lt;el-input v-model.number=&quot;ruleForm.age&quot;&gt;&lt;/el-input&gt;
  &lt;/el-form-item&gt;
  &lt;el-form-item&gt;
    &lt;el-button type=&quot;primary&quot; @click=&quot;submitForm('ruleForm')&quot;&gt;提交&lt;/el-button&gt;
    &lt;el-button @click=&quot;resetForm('ruleForm')&quot;&gt;重置&lt;/el-button&gt;
  &lt;/el-form-item&gt;
&lt;/el-form&gt;
&lt;script&gt;
  export default {
    data() {
      var checkAge = (rule, value, callback) =&gt; {
        if (!value) {
          return callback(new Error('年龄不能为空'))
        }
        setTimeout(() =&gt; {
          if (!Number.isInteger(parseInt(value))) {
            callback(new Error('请输入数字值'))
          } else {
            if (value &lt; 18) {
              callback(new Error('必须年满18岁'))
            } else {
              callback()
            }
          }
        }, 1000)
      }
      var validatePass = (rule, value, callback) =&gt; {
        if (value === '') {
          callback(new Error('请输入密码'))
        } else {
          if (this.ruleForm.checkPass !== '') {
            this.$refs.ruleForm.validateField('checkPass')
          }
          callback()
        }
      }
      var validatePass2 = (rule, value, callback) =&gt; {
        if (value === '') {
          callback(new Error('请再次输入密码'))
        } else if (value !== this.ruleForm.pass) {
          callback(new Error('两次输入密码不一致!'))
        } else {
          callback()
        }
      }
      return {
        ruleForm: {
          pass: '',
          checkPass: '',
          age: ''
        },
        rules: {
          pass: [{ validator: validatePass, trigger: 'blur' }],
          checkPass: [{ validator: validatePass2, trigger: 'blur' }],
          age: [{ validator: checkAge, trigger: 'blur' }]
        }
      }
    },
    methods: {
      submitForm(formName) {
        this.$refs[formName].validate((valid) =&gt; {
          if (valid) {
            alert('submit!')
          } else {
            console.log('error submit!!')
            return false
          }
        })
      },
      resetForm(formName) {
        this.$refs[formName].resetFields()
      }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><div class="tip">
<p>自定义校验 callback 必须被调用。 更多高级用法可参考 <a href="https://github.com/yiminghe/async-validator">async-validator</a>。</p>
</div>
<h3>Form Attributes</h3>
<table>
<thead>
<tr>
<th>参数</th>
<th>说明</th>
<th>类型</th>
<th>可选值</th>
<th>默认值</th>
</tr>
</thead>
<tbody>
<tr>
<td>model</td>
<td>表单数据对象</td>
<td>object</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>rules</td>
<td>表单验证规则</td>
<td>object</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>inline</td>
<td>行内表单模式</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>label-position</td>
<td>表单域标签的位置，如果值为 left 或者 right 时，则需要设置 <code>label-width</code></td>
<td>string</td>
<td>right/left/top</td>
<td>right</td>
</tr>
<tr>
<td>label-width</td>
<td>表单域标签的宽度，例如 '50px'。作为 Form 直接子元素的 form-item 会继承该值。支持 <code>auto</code>。</td>
<td>string</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>label-suffix</td>
<td>表单域标签的后缀</td>
<td>string</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>hide-required-asterisk</td>
<td>是否显示必填字段的标签旁边的红色星号</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>show-message</td>
<td>是否显示校验错误信息</td>
<td>boolean</td>
<td>—</td>
<td>true</td>
</tr>
<tr>
<td>inline-message</td>
<td>是否以行内形式展示校验信息</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>status-icon</td>
<td>是否在输入框中显示校验结果反馈图标</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>validate-on-rule-change</td>
<td>是否在 <code>rules</code> 属性改变后立即触发一次验证</td>
<td>boolean</td>
<td>—</td>
<td>true</td>
</tr>
<tr>
<td>size</td>
<td>用于控制该表单内组件的尺寸</td>
<td>string</td>
<td>medium / small / mini</td>
<td>—</td>
</tr>
<tr>
<td>disabled</td>
<td>是否禁用该表单内的所有组件。若设置为 true，则表单内组件上的 disabled 属性不再生效</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
</tbody>
</table>
<h3>Form Methods</h3>
<table>
<thead>
<tr>
<th>方法名</th>
<th>说明</th>
<th>参数</th>
</tr>
</thead>
<tbody>
<tr>
<td>validate</td>
<td>对整个表单进行校验的方法，参数为一个回调函数。该回调函数会在校验结束后被调用，并传入两个参数：是否校验成功和未通过校验的字段。若不传入回调函数，则会返回一个 promise</td>
<td>Function(callback: Function(boolean, object))</td>
</tr>
<tr>
<td>validateField</td>
<td>对部分表单字段进行校验的方法</td>
<td>Function(props: array | string, callback: Function(errorMessage: string))</td>
</tr>
<tr>
<td>resetFields</td>
<td>对整个表单进行重置，将所有字段值重置为初始值并移除校验结果</td>
<td>—</td>
</tr>
<tr>
<td>clearValidate</td>
<td>移除表单项的校验结果。传入待移除的表单项的 prop 属性或者 prop 组成的数组，如不传则移除整个表单的校验结果</td>
<td>Function(props: array | string)</td>
</tr>
</tbody>
</table>
<h3>Form Events</h3>
<table>
<thead>
<tr>
<th>事件名称</th>
<th>说明</th>
<th>回调参数</th>
</tr>
</thead>
<tbody>
<tr>
<td>validate</td>
<td>任一表单项被校验后触发</td>
<td>被校验的表单项 prop 值，校验是否通过，错误消息（如果存在）</td>
</tr>
</tbody>
</table>
<h3>Form-Item Attributes</h3>
<table>
<thead>
<tr>
<th>参数</th>
<th>说明</th>
<th>类型</th>
<th>可选值</th>
<th>默认值</th>
</tr>
</thead>
<tbody>
<tr>
<td>prop</td>
<td>表单域 model 字段，在使用 validate、resetFields 方法的情况下，该属性是必填的</td>
<td>string</td>
<td>传入 Form 组件的 <code>model</code> 中的字段</td>
<td>—</td>
</tr>
<tr>
<td>label</td>
<td>标签文本</td>
<td>string</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>label-width</td>
<td>表单域标签的的宽度，例如 '50px'。支持 <code>auto</code>。</td>
<td>string</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>required</td>
<td>是否必填，如不设置，则会根据校验规则自动生成</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>rules</td>
<td>表单验证规则</td>
<td>object</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>error</td>
<td>表单域验证错误信息, 设置该值会使表单验证状态变为<code>error</code>，并显示该错误信息</td>
<td>string</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>show-message</td>
<td>是否显示校验错误信息</td>
<td>boolean</td>
<td>—</td>
<td>true</td>
</tr>
<tr>
<td>inline-message</td>
<td>以行内形式展示校验信息</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>size</td>
<td>用于控制该表单域下组件的尺寸</td>
<td>string</td>
<td>medium / small / mini</td>
<td>-</td>
</tr>
</tbody>
</table>
<h3>Form-Item Slot</h3>
<table>
<thead>
<tr>
<th>name</th>
<th>说明</th>
</tr>
</thead>
<tbody>
<tr>
<td>—</td>
<td>Form Item 的内容</td>
</tr>
<tr>
<td>label</td>
<td>标签文本的内容</td>
</tr>
</tbody>
</table>
<h3>Form-Item Scoped Slot</h3>
<table>
<thead>
<tr>
<th>name</th>
<th>说明</th>
</tr>
</thead>
<tbody>
<tr>
<td>error</td>
<td>自定义表单校验信息的显示方式，参数为 { error }</td>
</tr>
</tbody>
</table>
<h3>Form-Item Methods</h3>
<table>
<thead>
<tr>
<th>方法名</th>
<th>说明</th>
<th>参数</th>
</tr>
</thead>
<tbody>
<tr>
<td>resetField</td>
<td>对该表单项进行重置，将其值重置为初始值并移除校验结果</td>
<td>-</td>
</tr>
<tr>
<td>clearValidate</td>
<td>移除该表单项的校验结果</td>
<td>-</td>
</tr>
</tbody>
</table>

      </section>
    </template>
    <script>
      import hljs from 'highlight.js'
      import * as Vue from "vue"
      export default {
        name: 'component-doc',
        components: {
          "element-demo0": (function() {
    
    const { resolveComponent: _resolveComponent, createVNode: _createVNode, withCtx: _withCtx, createTextVNode: _createTextVNode, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_el_switch = _resolveComponent("el-switch")
  const _component_el_checkbox = _resolveComponent("el-checkbox")
  const _component_el_checkbox_group = _resolveComponent("el-checkbox-group")
  const _component_el_radio = _resolveComponent("el-radio")
  const _component_el_radio_group = _resolveComponent("el-radio-group")
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_form = _resolveComponent("el-form")

  return (_openBlock(), _createBlock(_component_el_form, {
    ref: "formRef",
    model: _ctx.form,
    "label-width": "auto"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form_item, { label: "活动名称" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            modelValue: _ctx.form.name,
            "onUpdate:modelValue": $event => (_ctx.form.name = $event)
          }, null, 8, ["modelValue", "onUpdate:modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, { label: "即时配送" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_switch, {
            modelValue: _ctx.form.delivery,
            "onUpdate:modelValue": $event => (_ctx.form.delivery = $event)
          }, null, 8, ["modelValue", "onUpdate:modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, { label: "活动性质" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_checkbox_group, {
            modelValue: _ctx.form.type,
            "onUpdate:modelValue": $event => (_ctx.form.type = $event)
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_checkbox, {
                label: "美食/餐厅线上活动",
                name: "type"
              }),
              _createVNode(_component_el_checkbox, {
                label: "地推活动",
                name: "type"
              }),
              _createVNode(_component_el_checkbox, {
                label: "线下主题活动",
                name: "type"
              }),
              _createVNode(_component_el_checkbox, {
                label: "单纯品牌曝光",
                name: "type"
              })
            ]),
            _: 1
          }, 8, ["modelValue", "onUpdate:modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, { label: "特殊资源" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_radio_group, {
            modelValue: _ctx.form.resource,
            "onUpdate:modelValue": $event => (_ctx.form.resource = $event)
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_radio, { label: "线上品牌商赞助" }),
              _createVNode(_component_el_radio, { label: "线下场地免费" })
            ]),
            _: 1
          }, 8, ["modelValue", "onUpdate:modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, { label: "活动形式" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            type: "textarea",
            modelValue: _ctx.form.desc,
            "onUpdate:modelValue": $event => (_ctx.form.desc = $event)
          }, null, 8, ["modelValue", "onUpdate:modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: _ctx.onSubmit
          }, {
            default: _withCtx(() => [
              _createTextVNode("立即创建")
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_el_button, null, {
            default: _withCtx(() => [
              _createTextVNode("取消")
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model"]))
}
  
    
        const { ref, reactive, toRefs } = Vue
        
  const democomponentExport = {
    setup() {
      const formRef = ref(null)
      const data = reactive({
        form: {
          name: '',
          region: '',
          date1: '',
          date2: '',
          delivery: false,
          type: [],
          resource: '',
          desc: ''
        }
      })
      const onSubmit = () => {
        console.log('submit!')
      }

      return {
        ...toRefs(data),
        formRef,
        onSubmit
      }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo1": (function() {
    
    const { resolveComponent: _resolveComponent, createVNode: _createVNode, withCtx: _withCtx, createTextVNode: _createTextVNode, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_el_switch = _resolveComponent("el-switch")
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_form = _resolveComponent("el-form")

  return (_openBlock(), _createBlock(_component_el_form, {
    inline: true,
    model: _ctx.formInline,
    class: "demo-form-inline"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form_item, { label: "审批人" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            modelValue: _ctx.formInline.user,
            "onUpdate:modelValue": $event => (_ctx.formInline.user = $event),
            placeholder: "审批人"
          }, null, 8, ["modelValue", "onUpdate:modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, { label: "发送通知" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_switch, {
            modelValue: _ctx.formInline.delivery,
            "onUpdate:modelValue": $event => (_ctx.formInline.delivery = $event)
          }, null, 8, ["modelValue", "onUpdate:modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: _ctx.onSubmit
          }, {
            default: _withCtx(() => [
              _createTextVNode("查询")
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model"]))
}
  
    
        const { reactive, toRefs } = Vue
        
  const democomponentExport = {
    setup() {
      const data = reactive({
        formInline: {
          user: '',
          delivery: false
        }
      })
      const onSubmit = () => {
        console.log('submit!')
      }

      return {
        ...toRefs(data),
        onSubmit
      }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo2": (function() {
    
    const { createTextVNode: _createTextVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, createVNode: _createVNode, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_radio_button = _resolveComponent("el-radio-button")
  const _component_el_radio_group = _resolveComponent("el-radio-group")
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_el_form = _resolveComponent("el-form")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_el_radio_group, {
      modelValue: _ctx.labelPosition,
      "onUpdate:modelValue": $event => (_ctx.labelPosition = $event),
      size: "small"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_radio_button, { label: "left" }, {
          default: _withCtx(() => [
            _createTextVNode("左对齐")
          ]),
          _: 1
        }),
        _createVNode(_component_el_radio_button, { label: "right" }, {
          default: _withCtx(() => [
            _createTextVNode("右对齐")
          ]),
          _: 1
        }),
        _createVNode(_component_el_radio_button, { label: "top" }, {
          default: _withCtx(() => [
            _createTextVNode("顶部对齐")
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue", "onUpdate:modelValue"]),
    _createVNode("div", { style: {"margin":"20px"} }),
    _createVNode(_component_el_form, {
      "label-position": _ctx.labelPosition,
      "label-width": "80px",
      model: _ctx.formLabelAlign
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form_item, { label: "名称" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: _ctx.formLabelAlign.name,
              "onUpdate:modelValue": $event => (_ctx.formLabelAlign.name = $event)
            }, null, 8, ["modelValue", "onUpdate:modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, { label: "活动区域" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: _ctx.formLabelAlign.region,
              "onUpdate:modelValue": $event => (_ctx.formLabelAlign.region = $event)
            }, null, 8, ["modelValue", "onUpdate:modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, { label: "活动形式" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: _ctx.formLabelAlign.type,
              "onUpdate:modelValue": $event => (_ctx.formLabelAlign.type = $event)
            }, null, 8, ["modelValue", "onUpdate:modelValue"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["label-position", "model"])
  ], 64))
}
  
    
        const { reactive, toRefs, ref } = Vue
        
  const democomponentExport = {
    setup() {
      const labelPosition = ref('right')
      const data = reactive({
        formLabelAlign: {
          name: '',
          region: '',
          type: ''
        }
      })
      return {
        labelPosition,
        ...toRefs(data)
      }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo3": (function() {
    
    const { resolveComponent: _resolveComponent, createVNode: _createVNode, withCtx: _withCtx, createTextVNode: _createTextVNode, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_el_option = _resolveComponent("el-option")
  const _component_el_select = _resolveComponent("el-select")
  const _component_el_switch = _resolveComponent("el-switch")
  const _component_el_checkbox = _resolveComponent("el-checkbox")
  const _component_el_checkbox_group = _resolveComponent("el-checkbox-group")
  const _component_el_radio = _resolveComponent("el-radio")
  const _component_el_radio_group = _resolveComponent("el-radio-group")
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_form = _resolveComponent("el-form")

  return (_openBlock(), _createBlock(_component_el_form, {
    model: _ctx.ruleForm,
    rules: _ctx.rules,
    ref: "form",
    "label-width": "100px",
    class: "demo-ruleForm"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form_item, {
        label: "活动名称",
        prop: "name"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            modelValue: _ctx.ruleForm.name,
            "onUpdate:modelValue": $event => (_ctx.ruleForm.name = $event)
          }, null, 8, ["modelValue", "onUpdate:modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, {
        label: "活动区域",
        prop: "region"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_select, {
            modelValue: _ctx.ruleForm.region,
            "onUpdate:modelValue": $event => (_ctx.ruleForm.region = $event),
            placeholder: "请选择活动区域"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_option, {
                label: "区域一",
                value: "shanghai"
              }),
              _createVNode(_component_el_option, {
                label: "区域二",
                value: "beijing"
              })
            ]),
            _: 1
          }, 8, ["modelValue", "onUpdate:modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, {
        label: "即时配送",
        prop: "delivery"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_switch, {
            modelValue: _ctx.ruleForm.delivery,
            "onUpdate:modelValue": $event => (_ctx.ruleForm.delivery = $event)
          }, null, 8, ["modelValue", "onUpdate:modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, {
        label: "活动性质",
        prop: "type"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_checkbox_group, {
            modelValue: _ctx.ruleForm.type,
            "onUpdate:modelValue": $event => (_ctx.ruleForm.type = $event)
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_checkbox, {
                label: "美食/餐厅线上活动",
                name: "type"
              }),
              _createVNode(_component_el_checkbox, {
                label: "地推活动",
                name: "type"
              }),
              _createVNode(_component_el_checkbox, {
                label: "线下主题活动",
                name: "type"
              }),
              _createVNode(_component_el_checkbox, {
                label: "单纯品牌曝光",
                name: "type"
              })
            ]),
            _: 1
          }, 8, ["modelValue", "onUpdate:modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, {
        label: "特殊资源",
        prop: "resource"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_radio_group, {
            modelValue: _ctx.ruleForm.resource,
            "onUpdate:modelValue": $event => (_ctx.ruleForm.resource = $event)
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_radio, { label: "线上品牌商赞助" }),
              _createVNode(_component_el_radio, { label: "线下场地免费" })
            ]),
            _: 1
          }, 8, ["modelValue", "onUpdate:modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, {
        label: "活动形式",
        prop: "desc"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            type: "textarea",
            modelValue: _ctx.ruleForm.desc,
            "onUpdate:modelValue": $event => (_ctx.ruleForm.desc = $event)
          }, null, 8, ["modelValue", "onUpdate:modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: $event => (_ctx.submitForm('ruleForm'))
          }, {
            default: _withCtx(() => [
              _createTextVNode("立即创建")
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_el_button, {
            onClick: $event => (_ctx.resetForm('ruleForm'))
          }, {
            default: _withCtx(() => [
              _createTextVNode("重置")
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model", "rules"]))
}
  
    
        const { ref, reactive, toRefs, getCurrentInstance } = Vue
        
  const democomponentExport = {
    setup() {
      const form = ref(null)
      const ruleForm = reactive({
        ruleForm: {
          name: '',
          region: '',
          delivery: false,
          type: [],
          resource: '',
          desc: ''
        }
      })
      const rules = reactive({
        rules: {
          name: [
            { required: true, message: '请输入活动名称', trigger: 'blur' },
            { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
          ],
          region: [
            { required: true, message: '请选择活动区域', trigger: 'change' }
          ],
          type: [
            {
              type: 'array',
              required: true,
              message: '请至少选择一个活动性质',
              trigger: 'change'
            }
          ],
          resource: [
            { required: true, message: '请选择活动资源', trigger: 'change' }
          ],
          desc: [{ required: true, message: '请填写活动形式', trigger: 'blur' }]
        }
      })
      function submitForm() {
        form.value.validate((valid) => {
          if (valid) {
            alert('submit!')
          } else {
            console.log('error submit!!')
            return false
          }
        })
      }
      function resetForm() {
        form.value.resetFields()
      }

      return {
        form,
        ...toRefs(ruleForm),
        ...toRefs(rules),
        submitForm,
        resetForm
      }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo4": (function() {
    
    const { resolveComponent: _resolveComponent, createVNode: _createVNode, withCtx: _withCtx, createTextVNode: _createTextVNode, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_form = _resolveComponent("el-form")

  return (_openBlock(), _createBlock(_component_el_form, {
    model: _ctx.ruleForm,
    "status-icon": "",
    rules: _ctx.rules,
    ref: "ruleForm",
    "label-width": "100px",
    class: "demo-ruleForm"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form_item, {
        label: "密码",
        prop: "pass"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            type: "password",
            modelValue: _ctx.ruleForm.pass,
            "onUpdate:modelValue": $event => (_ctx.ruleForm.pass = $event),
            autocomplete: "off"
          }, null, 8, ["modelValue", "onUpdate:modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, {
        label: "确认密码",
        prop: "checkPass"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            type: "password",
            modelValue: _ctx.ruleForm.checkPass,
            "onUpdate:modelValue": $event => (_ctx.ruleForm.checkPass = $event),
            autocomplete: "off"
          }, null, 8, ["modelValue", "onUpdate:modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, {
        label: "年龄",
        prop: "age"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            modelValue: _ctx.ruleForm.age,
            "onUpdate:modelValue": $event => (_ctx.ruleForm.age = $event),
            modelModifiers: { number: true }
          }, null, 8, ["modelValue", "onUpdate:modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_el_form_item, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: $event => (_ctx.submitForm('ruleForm'))
          }, {
            default: _withCtx(() => [
              _createTextVNode("提交")
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_el_button, {
            onClick: $event => (_ctx.resetForm('ruleForm'))
          }, {
            default: _withCtx(() => [
              _createTextVNode("重置")
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model", "rules"]))
}
  
    const democomponentExport = {
    data() {
      var checkAge = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('年龄不能为空'))
        }
        setTimeout(() => {
          if (!Number.isInteger(parseInt(value))) {
            callback(new Error('请输入数字值'))
          } else {
            if (value < 18) {
              callback(new Error('必须年满18岁'))
            } else {
              callback()
            }
          }
        }, 1000)
      }
      var validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入密码'))
        } else {
          if (this.ruleForm.checkPass !== '') {
            this.$refs.ruleForm.validateField('checkPass')
          }
          callback()
        }
      }
      var validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'))
        } else if (value !== this.ruleForm.pass) {
          callback(new Error('两次输入密码不一致!'))
        } else {
          callback()
        }
      }
      return {
        ruleForm: {
          pass: '',
          checkPass: '',
          age: ''
        },
        rules: {
          pass: [{ validator: validatePass, trigger: 'blur' }],
          checkPass: [{ validator: validatePass2, trigger: 'blur' }],
          age: [{ validator: checkAge, trigger: 'blur' }]
        }
      }
    },
    methods: {
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            alert('submit!')
          } else {
            console.log('error submit!!')
            return false
          }
        })
      },
      resetForm(formName) {
        this.$refs[formName].resetFields()
      }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),
        }
      }
    </script>
  